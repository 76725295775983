import React, { useCallback, useState, useEffect, Fragment } from 'react'
import { Box, Grid, makeStyles, Select, MenuItem, CircularProgress, Typography, Button } from '@material-ui/core'
import { addDays, subDays, startOfISOWeek, isBefore, isAfter, startOfWeek, endOfWeek } from 'date-fns'
import { isSameDay } from 'date-fns/esm'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import Modal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import OrderBrowser from './WeekBrowser'
import { mapWeekByDate, mapOrderDataToBackUpdate, mapOrderDataToBackCreate } from '../utils/orderMapper'
import DayDateTitle from './DayDateTitle'
import OrderTab from './OrderTab'
import OrderForm from './OrderForm'
import NewOrderTab from './NewOrderTab'
import CancelModal from './CancelModal'
import { statuses } from '../utils/constants'
import ConfirmOrderModal from './ConfirmOrderModal'
import uuid from 'uuid/v4'
import { zonedTimeToUtc } from 'date-fns-tz'
import {zonedToFinnishTime} from "../utils/orderUtils";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },

    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
        top: '60px',
    },

    listContainer: {
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '320px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '600px',
        },
        backgroundColor: '#FFF',
        margin: '0 !important',
        boxShadow: '0px 3px 6px #00000029',
        padding: '2em',
    },

    tab: {
        width: '100%',
        maxWidth: '720px',
        marginBottom: theme.spacing(1),
    },
    dayDateTitle: {
        width: '100%',
        maxWidth: '720px',
    },
    contactNameSelect: {
        width: '100%',
        maxWidth: '720px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    loadingSpinner: {
        margin: theme.spacing(4),
    },
}))

const formModalStyles = {
    content: {
        top: '32px',
        left: '50%',
        right: 'auto',
        bottom: '32px',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%)',
    },
    overlay: {
        zIndex: 1200,
    },
}

const cancelModalStyles = {
    content: {
        height: '240px',
        width: '280px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: 1201,
    }
}

const confirmModalStyles = {
    content: {
        height: '460px',
        width: '300px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: 1201,
    },
}

const Orders = () => {
    const { oidcUser } = useReactOidc()
    const { profile } = oidcUser
    const classes = useStyles()
    const [formModalIsOpen, setFormModalIsOpen] = useState(false)
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false)
    const [formModalContent, setFormModalContent] = useState(null)
    const [cancelModalContent, setCancelModalContent] = useState(null)
    const [confirmModalContent, setConfirmModalContent] = useState(null)
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(startOfISOWeek(new Date()))
    const [contactNameSelected, setContactNameSelected] = useState('all')
    const [passengerUserPreSelected, setPassengerUserPreSelected] = useState(null)
    const [vehicleCategories, setVehicleCategories] = useState(null)
    const [organizationUsers, setOrganizationUsers] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)
    const [rideData, setRideData] = useState([])

    const emptyRouteSteps = [{
        id: uuid(),
        address: '',
        stepOrder: 1,
        plannedPickupDate: null,
    },
    {
        id: uuid(),
        address: '',
        stepOrder: 2,
        plannedPickupDate: null,
    }]

    const loadOrderData = useCallback((page, previousData) => {
        setIsLoading(true)
        const pageIndex = page || 1
        const startDate = startOfWeek(firstDayOfWeek, { weekStartsOn: 1 }).toISOString()
        const endDate = endOfWeek(firstDayOfWeek, { weekStartsOn: 1 }).toISOString()
        const url = `/api/Rides?page=${pageIndex}&pageSize=50&showCancelled=true&startDate=${startDate}&endDate=${endDate}`
        const currentData = previousData ?? []
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                enqueueSnackbar(<FormattedMessage id="order_get_rides_error" />, { variant: 'error' })
            }).then((data) => {
                if (data && data.results) {
                    if (pageIndex > 20) {
                        enqueueSnackbar(<FormattedMessage id="serverError_too_much_data_to_load" />, { variant: 'error' })
                    }
                    if (pageIndex * data.pageSize < data.totalCount) {
                        // Load more pages
                        loadOrderData(pageIndex + 1, [...currentData, ...data.results])
                    } else {
                        // Last page --> show data
                        setRideData([...currentData, ...data.results])
                        setIsLoading(false)
                    }
                } else {
                    setRideData([])
                    setIsLoading(false)
                }
            })
            .catch(() => {
                enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: 'error' })
            })
    }, [enqueueSnackbar, firstDayOfWeek])

    useEffect(() => {
        const passengerUserData = organizationUsers.find((orgUser) => orgUser.email === contactNameSelected)
        setPassengerUserPreSelected(passengerUserData)
    }, [contactNameSelected, organizationUsers])

    useEffect(() => {
        // week changed
        if (firstDayOfWeek !== null) {
            loadOrderData(1, [])
        }
    }, [loadOrderData, firstDayOfWeek])

    useEffect(() => {
        fetch(`/api/vehiclecategories`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return enqueueSnackbar(<FormattedMessage id="order_get_vehicleCategories_error" />, { variant: "error" });
                }
            })
            .then(vehicleCategories => {
                setVehicleCategories(vehicleCategories)
                // Precache vehicle images
                vehicleCategories.map((car) => {
                    const img = new Image();
                    img.src = car.imagePath;
                })
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" }))
    }, [enqueueSnackbar])

    useEffect(() => {
        if (profile && profile.org) {
            fetch(`/api/organizations/${profile.org}/users`)
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        return enqueueSnackbar(<FormattedMessage id="order_get_organizationUsers_error" />, { variant: "error" });
                    }
                })
                .then((users) => {
                    setOrganizationUsers(users.filter((user) => user.fullName?.length))
                })
                .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" }))
        }
        else {
            return console.log("no user profile found")
        }
    }, [enqueueSnackbar, profile])

    const cancelRide = (rideId) => {
        const orderToCancel = rideData.find((ride) => ride.id === rideId)
        const timeLimitHoursForOrderCancellation = vehicleCategories.filter((vehicle) => vehicle.id === orderToCancel.requestedVehicleClassGuid)[0].timeLimitHoursForOrderCancellation

        setCancelModalContent(<CancelModal onCancelClick={() => cancelRideCall()} onCloseClick={() => closeCancelModal()} plannedPickupDate={orderToCancel.plannedPickupDate} timeLimitHoursForOrderCancellation={timeLimitHoursForOrderCancellation} />)
        openCancelModal()

        const cancelRideCall = () => {
            fetch(`/api/rides/${rideId}`, {
                method: "DELETE"
            })
                .then((response) => {
                    if (response.ok) {
                        loadOrderData()
                        return enqueueSnackbar(<FormattedMessage id="ride_cancel_success" />, { variant: "success" });
                    } else {
                        enqueueSnackbar(<FormattedMessage id="ride_cancel_failed" />, { variant: "error" });
                    }
                })
                .catch(() => {
                    enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" });
                })
                .finally(() => { closeCancelModal(); closeFormModal() })
        }
    }

    const confirmOrder = (data) => {
        setConfirmModalContent(
            <ConfirmOrderModal
                onCloseClick={() => closeConfirmModal()} onConfirmClick={(requestEmailConfirmation) => confirmOrderCall(requestEmailConfirmation)}
                timeLimitHoursForOrderModification={data.timeLimitHoursForOrderModification}
                timeLimitHoursForOrderCancellation={data.timeLimitHoursForOrderCancellation}
            />)
        openConfirmModal()

        const confirmOrderCall = (requestEmailConfirmation) => {
            data.sendConfirmationEmailToSubscriber = requestEmailConfirmation
            const mappedData = mapOrderDataToBackCreate(data)
            createOrder(mappedData)
            closeConfirmModal()
            closeFormModal()
        }

    }

    const openFormModal = () => {
        setFormModalIsOpen(true)
        disableBodyScroll(document.querySelector('#modal'))
    }

    const closeFormModal = () => {
        setFormModalIsOpen(false)
        enableBodyScroll(document.querySelector('#modal'))
    }

    const openCancelModal = () => {
        setCancelModalIsOpen(true)
        disableBodyScroll(document.querySelector('#cancel-modal'))
    }

    const closeCancelModal = () => {
        setCancelModalIsOpen(false)
        enableBodyScroll(document.querySelector('#cancel-modal'))
    }

    const openConfirmModal = () => {
        setConfirmModalIsOpen(true)
    }

    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false)
    }

    const updateOrder = (data) => {
        enqueueSnackbar(<FormattedMessage id="order_save_progress" />, { variant: "info" });
        fetch(`/api/orders/${data.id}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                ...data
            })
        })
            .then((response) => {
                if (!response.ok) { throw response }
                return response
            }).then(() => {
                // Upload possible new attachments..
                for (let d in data.filesToUpload) {
                    uploadFile(data.filesToUpload[d], data.id)
                }
                loadOrderData()
                return enqueueSnackbar(<FormattedMessage id="order_save_success" />, { variant: "success" });
            })
            .catch((error) => {
                if (error.text) {
                    error.text().then(errorMessage => {
                        const errorMsg = errorMessage.slice(1, -1); //remove double quotation marks
                        enqueueSnackbar(<FormattedMessage id={errorMsg} />, { variant: "error" })
                    })
                } else {
                    enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" })
                }
            })
            .finally(() => closeFormModal())
    }

    const uploadFile = (file, orderId) => {
        const formData = new FormData()
        formData.append('body', file)
        fetch(`/api/Attachments?orderId=${orderId}`, {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    return enqueueSnackbar(<FormattedMessage id="order_file_upload_error" />, { variant: "error" });
                }
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" }))
    }

    const createOrder = (data) => {
        enqueueSnackbar(<FormattedMessage id="order_create_progress" />, { variant: "info" });
        fetch('/api/Orders', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                ...data
            })
        })
            .then((response) => {
                if (!response.ok) {
                    return enqueueSnackbar(<FormattedMessage id="order_create_error" />, { variant: "error" });
                } else {
                    return response.json()
                }
            }).then((res) => {
                for (var d in data.filesToUpload) {
                    uploadFile(data.filesToUpload[d], res.id)
                }
                loadOrderData()
                return enqueueSnackbar(<FormattedMessage id="order_create_success" />, { variant: "success" });
            }
            )
            .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" }))
            .finally(() => closeFormModal())
    }

    const handlePrevClick = () => {
        setFirstDayOfWeek(subDays(firstDayOfWeek, 7))
    }

    const handleNextClick = () => {
        setFirstDayOfWeek(addDays(firstDayOfWeek, 7))
    }

    const filterDataByOrganizationUserName = (data) => {
        if (data && contactNameSelected && contactNameSelected !== "all") {
            return data.filter((ride) => (ride.order.passengerUser ? ride.order.passengerUser.email : ride.order.otherPassengerName) === contactNameSelected)
        }
        return data
    }

    const onSubmitForm = (data) => {
        if (!data.id) {

            // Creating new order.. fetch vehicle category details for modal
            fetch(`/api/VehicleCategories/${data.requestedVehicleClassGuid}`, {
                method: "GET"
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Getting Vehicle Category details failed');
                    } else {
                        return response.json()
                    }
                }).then((res) => {
                    data.timeLimitHoursForOrderModification = res.timeLimitHoursForOrderModification;
                    data.timeLimitHoursForOrderCancellation = res.timeLimitHoursForOrderCancellation;
                    confirmOrder(data)
                })

        } else {

            updateOrder(mapOrderDataToBackUpdate(data))
        }
    }

    const isOrderInPast = (date) => isAfter(new Date(date), new Date())

    const formatDataBeforeSubmit = (formData, orderId, rideId) => {
        return {
            ...formData,
            id: orderId || null,
            rideId: rideId || null,
        }
    }

    const downloadCsv = () => {
        const startDate = startOfWeek(firstDayOfWeek, { weekStartsOn: 1 }).toISOString()
        const endDate = endOfWeek(firstDayOfWeek, { weekStartsOn: 1 }).toISOString()
        const url = `/api/Rides/csv?showCancelled=true&startDate=${startDate}&endDate=${endDate}`
        const link = document.createElement('a')
        link.href = url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    return (
        <div className={classes.page}>
            <div className={classes.listContainer}>
                <div id="form-modal">
                    <Modal
                        testId="order-modal"
                        isOpen={formModalIsOpen}
                        onRequestClose={closeFormModal}
                        style={formModalStyles}
                        contentLabel="Form Modal"
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        {formModalContent}
                    </Modal>
                </div>
                <div id="cancel-modal">
                    <Modal
                        testId="order-cancel-modal"
                        isOpen={cancelModalIsOpen}
                        onRequestClose={closeCancelModal}
                        style={cancelModalStyles}
                        contentLabel="Cancel Modal"
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        {cancelModalContent}
                    </Modal>
                </div>
                <div id="confirm-modal">
                    <Modal
                        testId="order-confirm-modal"
                        isOpen={confirmModalIsOpen}
                        onRequestClose={closeConfirmModal}
                        style={confirmModalStyles}
                        contentLabel="Confirm Modal"
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        {confirmModalContent}
                    </Modal>
                </div>

                <Grid container justify="center" alignItems="center" direction="column" className={classes.container}>
                    <Grid item xs={12}>
                        <Box m={2} />
                        <Typography variant="h5"><FormattedMessage id="order_reserve_ride" /></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box m={3} />
                        <OrderBrowser 
                            handlePrevClick={handlePrevClick}
                            handleNextClick={handleNextClick}
                            date={firstDayOfWeek}
                            disableButtons={isLoading}
                        />
                    </Grid>
                </Grid>

                <Grid container justify="center" alignItems="center" direction="column" className={classes.container}>
                    <Grid item>
                        <Box m={2} />
                        <Select
                            className={classes.contactNameSelect}
                            name="contactNameSelect"
                            onChange={(e) => setContactNameSelected(e.target.value)}
                            value={contactNameSelected}
                            defaultValue="all"
                            fullWidth={true}
                        >
                            <MenuItem name="all" value="all"><FormattedMessage id="all" /></MenuItem>
                            {organizationUsers.map((person) => <MenuItem key={person.id} name={person.fullName} value={person.email}>{person.fullName}</MenuItem>)}
                        </Select>
                    </Grid>
                    {isLoading ? <CircularProgress className={classes.loadingSpinner} color="secondary" /> :
                        (firstDayOfWeek && mapWeekByDate(firstDayOfWeek).map((weekDay, weekIndex) =>
                            <Fragment key={`${weekDay}`}>
                                <br />
                                {<DayDateTitle date={weekDay} />}
                                {rideData && filterDataByOrganizationUserName(rideData).map((ride) => {
                                    const { id: rideId,
                                        routeSteps,
                                        plannedPickupDate,
                                        vehicleName,
                                        vehicleLicensePlate,
                                        driverName,
                                        driverPhone,
                                        number: rideNumber,
                                        status,
                                        requestedVehicleClassGuid,
                                        dateCancelled,
                                        estimatedTripLengthKm,
                                    } = ride
                                    const { id: orderId,
                                        additionalInformation,
                                        driveType,
                                        numberOfPassengers,
                                        showAdditionalInfoForPassenger,
                                        terminalPickup,
                                        subscriberCompany,
                                        subscriberUser,
                                        passengerUser,
                                        otherPassengerName,
                                        otherPassengerEmail,
                                        otherPassengerPhone,
                                        otherPassengerCompany,
                                        paymentType,
                                        paymentCreditCardGuid,
                                        number,
                                    } = ride.order
                                    return (
                                        isSameDay(new Date(plannedPickupDate), weekDay) && (
                                            <Fragment key={rideId}>
                                                <Grid item className={classes.tab} data-test-id={number}>
                                                    <OrderTab
                                                        orderData={{
                                                            id: orderId,
                                                            driveType,
                                                            plannedPickupDate,
                                                            requestedVehicleClassGuid,
                                                            routeSteps,
                                                            numberOfPassengers,
                                                            passengerName: (ride.order.passengerUser ? ride.order.passengerUser.fullName : ride.order.otherPassengerName) || '',
                                                            passengerOtherCompany: otherPassengerCompany,
                                                        }}
                                                        status={status}
                                                        isOrderCancelable={() => isOrderInPast(plannedPickupDate) && status !== statuses.Cancelled}
                                                        vehicleCategories={vehicleCategories}
                                                        rideId={rideId}
                                                        copyOrder={() => {
                                                            openFormModal(); setFormModalContent(
                                                                <OrderForm
                                                                    data={{
                                                                        id: null,
                                                                        requestedVehicleClassGuid,
                                                                        driverName,
                                                                        driveType: ride.order.driveType,
                                                                        driverPhone,
                                                                        vehicleName,
                                                                        vehicleLicensePlate,
                                                                        plannedPickupDate: null,
                                                                        routeSteps,
                                                                        orderNumber: null,
                                                                        additionalInformation: null,
                                                                        showAdditionalInfoForPassenger: null,
                                                                        terminalPickup: null,
                                                                        numberOfPassengers: null,
                                                                        status: statuses.Copy,
                                                                        passengerUser,
                                                                        otherPassengerName,
                                                                        otherPassengerEmail,
                                                                        otherPassengerPhone,
                                                                        otherPassengerCompany,
                                                                        subscriberUser,
                                                                        passengerName: (passengerUser ? passengerUser.fullName : otherPassengerName) || '',
                                                                        subscriberCompany,
                                                                        dateCancelled,
                                                                        paymentType,
                                                                        paymentCreditCardGuid,
                                                                    }}
                                                                    rideId={null}
                                                                    vehicleCategories={vehicleCategories}
                                                                    weekDay={weekDay}
                                                                    onSubmitForm={(formData) => onSubmitForm(formatDataBeforeSubmit(formData, null, null))}
                                                                    onClose={closeFormModal}
                                                                />
                                                            )
                                                        }}
                                                        cancelRide={() => cancelRide(rideId)}
                                                        onTabClick={() => {
                                                            openFormModal(); setFormModalContent(<OrderForm
                                                                data={{
                                                                    id: orderId,
                                                                    requestedVehicleClassGuid,
                                                                    driveType,
                                                                    driverName,
                                                                    driverPhone,
                                                                    vehicleName,
                                                                    vehicleLicensePlate,
                                                                    plannedPickupDate,
                                                                    routeSteps,
                                                                    orderNumber: number,
                                                                    additionalInformation,
                                                                    showAdditionalInfoForPassenger,
                                                                    terminalPickup,
                                                                    numberOfPassengers,
                                                                    status,
                                                                    passengerUser,
                                                                    otherPassengerName,
                                                                    otherPassengerEmail,
                                                                    otherPassengerPhone,
                                                                    otherPassengerCompany,
                                                                    subscriberUser,
                                                                    passengerName: (passengerUser ? passengerUser.fullName : otherPassengerName) || '',
                                                                    subscriberCompany,
                                                                    dateCancelled,
                                                                    estimatedTripLengthKm,
                                                                    paymentType,
                                                                    paymentCreditCardGuid,
                                                                }}
                                                                rideId={rideId}
                                                                vehicleCategories={vehicleCategories}
                                                                weekDay={weekDay}
                                                                onSubmitForm={(formData) => onSubmitForm(formatDataBeforeSubmit(formData, orderId, rideId))}
                                                                onClose={closeFormModal}
                                                                cancelRide={() => cancelRide(rideId)}
                                                                isOrderCancelable={() => isOrderInPast(plannedPickupDate)}
                                                            />)
                                                        }}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        )
                                    )
                                })
                                }

                                {!isBefore(new Date(weekDay), subDays(new Date(), 1)) &&
                                    <Grid item className={classes.tab} key={weekDay}>
                                        <NewOrderTab onTabClick={() => {
                                            openFormModal()
                                            setFormModalContent(
                                                <OrderForm
                                                    data={{
                                                        status: statuses.New,
                                                        passengerUser: passengerUserPreSelected,
                                                        driveType: 0,
                                                        routeSteps: emptyRouteSteps,
                                                    }}
                                                    rideId={null}
                                                    vehicleCategories={vehicleCategories}
                                                    weekDay={weekDay}
                                                    onSubmitForm={(formData) => {
                                                        formData.plannedPickupDate = zonedToFinnishTime(formData.plannedPickupDate);
                                                        onSubmitForm(formatDataBeforeSubmit(formData, null, null));
                                                    }}
                                                    organizationUsers={organizationUsers}
                                                    onClose={closeFormModal}
                                                />,
                                            )
                                        }}
                                        />
                                    </Grid>}
                            </Fragment>
                        ))}
                </Grid>
                <Grid container justify="center" alignItems="center" direction="column" className={classes.container}>
                    <Grid item>
                        <Box m={2} />
                    </Grid>
                    <Grid item>
                        <Button onClick={() => downloadCsv()}>
                            <FormattedMessage id="orders_download_all_week_orders_as_csv" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Orders
